const initialState = {
    count: 0,
    storeFavIconCount: 0,
    data: {},
    storeTempId: "",
    storeSectionUrl: "",
    storeEntityId: {},
    isJewelDIY: false,
    isDiamoDIY: false,
    storeFilterValueName: "",
    storeSubNavbarName: "",
    isLoginClassAdded: false,
    storeCurrency: "",
    storeHeaderData: {},
    currentStepperTab: "",
    singleDiamondDetail: {},
    itemDynamicParameter: {},
    isEditRingDiamond: "",
    activeTabValue: "",
    engravingObj: {},
    diamondJewelleryProduct: {},
    isDiyComplete: false,
    activeProductTab: "SETTING",
    diamondObj: {},
    stoneIndexObj: {},
    storeArry:[],
    itemGroupData:[],
    searchMastersData:[],
    storeItemDimensionData:[],
    storeFilterArry:[],
    storeIsButtonSelected:{},
    storeItemIdData:[{ key: 'item_id', value: '' }, { key: 'product_sku', value: '' }, { key: 'emr_number', value: '' }, { key: 'metal_weight', value: '' }, { key: 'diamond_weight', value: '' }, { key: 'gemstone_weight', value: '' }, { key: 'set_family_category', value: '' }, { key: 'related_products', value: '' }],
    storeSetFamilyDataSelected: {},
    storeSetRelatedDataSelected:{},
    storeProductDesignData:[],
    storeProductDesignInput:[],
    storeDimensionOptionData:{},
    storeDimensionData:{},
    storeDimDataAll:{},
    dimensionFilterArrData:[],
    storeSetSetRelatedData:[],
    storeSetSetFamilyData:[],
    storeFilteredValueData:{},
    storeSelectedEngraving: false,
    storeSelectedOffers:[],
    storeSelectedEngravingText:"",
    storeEmbossingData:[],
    saveEmbossings:false,
    previewImageDatas:[],
    activeImageData:[]
    
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "countOfCartItems": {
            return {
                ...state,
                count: action.count,
            };
        }

        case "isLoginClassAdded": {
            state.isLoginClassAdded = action.isLoginClassAdded;
            return {
                ...state,
                isLoginClassAdded: state.isLoginClassAdded,
            };
        }

        case "storeSectionUrl": {
            state.storeSectionUrl = action.storeSectionUrl;
            return {
                ...state,
                storeSectionUrl: state.storeSectionUrl,
            };
        }

        case "storeCurrency": {
            state.storeCurrency = action.storeCurrency;
            return {
                ...state,
                storeCurrency: state.storeCurrency,
            };
        }

        case "storeTempId": {
            state.storeTempId = action.storeTempId;
            return {
                ...state,
                storeTempId: state.storeTempId
            }
        }

        case "getLoginDetails": {
            state.data = action.data;
            return {
                ...state,
                data: state.data,
            };
        }

        case "storeSubNavbarName": {
            state.storeSubNavbarName = action.storeSubNavbarName;
            return {
                ...state,
                storeSubNavbarName: state.storeSubNavbarName,
            };
        }

        case "storeHeaderData": {
            state.storeHeaderData = action.storeHeaderData;
            return {
                ...state,
                storeHeaderData: state.storeHeaderData,
            };
        }

        case "isJewelDIY": {
            state.isJewelDIY = action.isJewelDIY;
            return {
                ...state,
                isJewelDIY: state.isJewelDIY,
            };
        }

        case "storeFilterValueName": {
            state.storeFilterValueName = action.storeFilterValueName;
            return {
                ...state,
                storeFilterValueName: state.storeFilterValueName,
            };
        }

        case "isDiamoDIY": {
            state.isDiamoDIY = action.isDiamoDIY;
            return {
                ...state,
                isDiamoDIY: state.isDiamoDIY,
            };
        }

        case "storeFavCount": {
            state.storeFavIconCount = action.storeFavCount;
            return {
                ...state,
                storeFavCount: state.storeFavIconCount,
            };
        }

        case "storeEntityId": {
            state.storeEntityId = action.storeEntityId;
            return {
                ...state,
                storeEntityId: state.storeEntityId,
            };
        }

        case "SET_STEPPER_TAB_NAME": {
            state.currentStepperTab = action.payload;
            return {
                ...state,
                currentStepperTab: action.payload,
            };
        }

        case "ADD_DIAMOND": {
            state.singleDiamondDetail = action.payload;
            return {
                ...state,
                singleDiamondDetail: action.payload,
            };
        }

        case "ADD_DYNAMIC_PARAMS": {
            state.itemDynamicParameter = action.payload;
            return {
                ...state,
                itemDynamicParameter: action.payload,
            };
        }

        case "EDIT_RING_DIAMOND": {
            state.isEditRingDiamond = action.payload;
            return {
                ...state,
                isEditRingDiamond: action.payload,
            };
        }

        case "SET_ACTIVE_TAB_NAME": {
            state.activeTabValue = action.payload;
            return {
                ...state,
                activeTabValue: action.payload,
            };
        }

        case "ADD_ENGRAVING": {
            return {
                ...state,
                engravingObj: action.payload,
            };
        }

        case "ADD_JEWELLERY_PRODUCT": {
            return {
                ...state,
                diamondJewelleryProduct: action.payload,
            }
        }

        case "COMPLETE_PRODUCT": {
            return {
                ...state,
                isDiyComplete: action.payload,
            }
        }

        case "ACTIVE_PRODUCT_TAB": {
            return {
                ...state,
                activeProductTab: action.payload,
            }
        }

        case "ADD_TO_RING": {
            return {
                ...state,
                diamondObj: action.payload,
            };
        }

        case "ADD_INDEX": {
            return {
                ...state,
                stoneIndexObj: action.payload,
            };
        }
        case "ARRAY_FILTER": {
            return {
                ...state,
                storeArry: action.payload,
            };
        }
        case "itemGroupData": {
            return {
                ...state,
                itemGroupData: action.payload,
            };
        }
        case "searchMastersData": {
            return {
                ...state,
                searchMastersData: action.payload,
            };
        }
        case "storeItemDimensionData": {
            return {
                ...state,
                storeItemDimensionData: action.payload,
            };
        }
        case "storeFilterArry": {
            return {
                ...state,
                storeFilterArry: action.payload,
            };
        }
        case "storeIsButtonSelected": {
            return {
                ...state,
                storeIsButtonSelected: action.payload,
            };
        }
        case "storeItemIdData": {
            return {
                ...state,
                storeItemIdData: action.payload,
            };
        }
        case "storeSetFamilyDataSelected": {
            return {
                ...state,
                storeSetFamilyDataSelected: action.payload,
            };
        }
        case "storeSetRelatedDataSelected": {
            return {
                ...state,
                storeSetRelatedDataSelected: action.payload,
            };
        }
        case "storeProductDesignData": {
            return {
                ...state,
                storeProductDesignData: action.payload,
            };
        }
        case "storeProductDesignInput": {
            return {
                ...state,
                storeProductDesignInput: action.payload,
            };
        }
        case "storeDimensionOptionData": {
            return {
                ...state,
                storeDimensionOptionData: action.payload,
            };
        }
        case "storeDimensionData": {
            return {
                ...state,
                storeDimensionData: action.payload,
            };
        }
        case "storeDimDataAll": {
            return {
                ...state,
                storeDimDataAll: action.payload,
            };
        }
        case "dimensionFilterArrData": {
            return {
                ...state,
                dimensionFilterArrData: action.payload,
            };
        }
        case "storeSetSetRelatedData": {
            return {
                ...state,
                storeSetSetRelatedData: action.payload,
            };
        }
        case "storeSetSetFamilyData": {
            return {
                ...state,
                storeSetSetFamilyData: action.payload,
            };
        }
        case "storeFilteredValueData": {
            return {
                ...state,
                storeFilteredValueData: action.payload,
            };
        }
        case "storeSelectedOffers": {
            return {
                ...state,
                storeSelectedOffers: action.payload,
            };
        }
        case "storeSelectedEngraving": {
            return {
                ...state,
                storeSelectedEngraving: action.payload,
            };
        }
        case "storeSelectedEngravingText": {
            return {
                ...state,
                storeSelectedEngravingText: action.payload,
            };
        }
        case "storeEmbossingData": {
            return {
              ...state,
              storeEmbossingData: action.payload,
            };
          }
          case "saveEmbossings": {
            return {
              ...state,
              saveEmbossings: action.payload,
            };
          }
         
          case "previewImageDatas": {
            return {
              ...state,
              previewImageDatas: action.payload,
            };
          }
          case "activeImageData": {
            return {
              ...state,
              activeImageData: action.payload,
            };
          }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default Reducer;
